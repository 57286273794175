var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"mt-1",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pr-2 pl-4"},[_c('v-row',[_c('v-col',{attrs:{"sm":"4","md":"4","lg":"2"}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('v-col',{attrs:{"sm":"4","md":"2","lg":"1"}},[_c('v-chip',{attrs:{"small":"","outlined":"","label":""}},[_vm._v(_vm._s(_vm.item.slug))])],1),_c('v-col',{attrs:{"sm":"4","md":"6","lg":"2"}},_vm._l((_vm.item.statistics),function(statisticItem,i){return _c('div',{key:i},[_c('router-link',{staticClass:"text-decoration-none grey--text text--darken-4",attrs:{"to":{
              name: 'AccountList',
              query: {
                tags: [_vm.item.id],
                target: [statisticItem.name],
                selectedFilters: ['tags', 'target'],
              },
            }}},[_c('v-icon',{domProps:{"textContent":_vm._s(
                'mdi-' +
                (statisticItem.name === 'vkontakte'
                  ? 'alpha-b-box'
                  : statisticItem.name)
              )}}),_c('span',{staticClass:"pl-1",domProps:{"textContent":_vm._s(statisticItem.value)}})],1)],1)}),0),_c('v-col',{attrs:{"sm":"6","md":"8","lg":"5"}},[(_vm.hasFormattedSegment)?_c('div',_vm._l((_vm.formattedSegment),function(segment,label){return _c('div',{key:label},[_c('span',{staticClass:"grey--text body-2 mb-0"},[_vm._v(" "+_vm._s(_vm.attributeLabels[label])+": ")]),_vm._v(" "+_vm._s(_vm.prepareAttributeValue(label, segment))+" ")])}),0):_c('div',{staticClass:"text-caption font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("tag.segmentNotSet"))+" ")])]),_c('v-col',{attrs:{"sm":"3","md":"2","lg":"1"}},[(_vm.item.settings.color.hex)?_c('v-chip',{attrs:{"small":"","color":_vm.item.settings.color.hex}},[_vm._v(_vm._s(_vm.item.settings.color.hex))]):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-space-between pr-5",attrs:{"sm":"3","md":"2","lg":"1"}},[(!_vm.item.settings.disabledMarkAccount)?_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('assignItem')}}},[_c('v-icon',[_vm._v("mdi-link-plus")])],1):_vm._e(),_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('editItem')}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),(!_vm.item.base)?_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('deleteItem')}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }