var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.$t("tag.listTitle")))])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","title":_vm.$t('tag.assignTags')},on:{"click":function($event){return _vm.assignItem(null)}}},[_vm._v(" "+_vm._s(_vm.$t("tag.assignTags"))+" ")]),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editCategoryItem(null)}}},[_vm._v(" "+_vm._s(_vm.$t("tag.addTagCategory"))+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('tag.searchText'),"flat":"","filled":"","rounded":"","dense":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.dataLoading,"justify-center":"","align-center":"","opacity":"0.2"}},[_c('v-progress-circular',{attrs:{"size":"64","indeterminate":""}})],1),_c('v-treeview',{ref:"tree",attrs:{"load-children":_vm.loadChildren,"items":_vm.preparedItems,"return-object":"","expand-icon":"mdi-chevron-down","item-text":"title","open":_vm.open,"transition":"","filter":_vm.filter,"search":_vm.search},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [(item.level === 0)?_c('tag-category',{attrs:{"item":item},on:{"addItem":function($event){return _vm.editItem({ parentId: item.id })},"editItem":function($event){return _vm.editCategoryItem(item)},"deleteItem":function($event){return _vm.deleteConfirmation(item)}}}):_c('tag-item',{attrs:{"item":item},on:{"editItem":function($event){return _vm.editItem(item)},"deleteItem":function($event){return _vm.deleteConfirmation(item)},"assignItem":function($event){return _vm.assignItem(item)}}})]}}])}),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[(_vm.editDialog)?_c('tag-form',{attrs:{"item":_vm.selectedItem},on:{"close":_vm.closeEditDialog,"submitted":function($event){_vm.loadItems();
        _vm.loadChildren({ id: _vm.selectedItem.parentId });
        _vm.closeEditDialog();}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.categoryDialog),callback:function ($$v) {_vm.categoryDialog=$$v},expression:"categoryDialog"}},[(_vm.categoryDialog)?_c('tag-category-form',{attrs:{"item":_vm.selectedCategory},on:{"close":_vm.closeCategoryDialog,"submitted":function($event){_vm.loadItems();
        _vm.closeCategoryDialog();}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.assignDialog),callback:function ($$v) {_vm.assignDialog=$$v},expression:"assignDialog"}},[(_vm.assignDialog)?_c('tag-assign-form',{attrs:{"item":_vm.selectedItem},on:{"close":_vm.closeAssignDialog,"submitted":_vm.closeAssignDialog}}):_vm._e()],1),(_vm.selectedItem)?_c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary lighten-1 white--text justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t("deleteConfirmation"))+" "),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){_vm.deleteDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(
          _vm.selectedItem.level > 0 ||
          (_vm.selectedItem.childrenStatistics &&
            _vm.selectedItem.childrenStatistics.length === 0)
        )?_c('v-card-text',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t("deleteConfirmationText", _vm.selectedItem))+" ")]):_c('v-card-text',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.deleteCategoryWarning)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItem(_vm.selectedItem)}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }