<template>
  <v-card>
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      <span v-if="item && item.id">
        {{
          $t("tag.editTag", {
            tag: item.title,
          })
        }}
      </span>
      <span v-else>
        {{ $t("tag.addTag") }}
      </span>
      <v-btn icon color="white" :title="$t('close')" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="px-3" style="position: relative">
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <v-text-field
          name="name"
          :label="attributeLabels.title"
          :error-messages="tagErrors.title"
          v-model="tag.title"
          type="text"
        ></v-text-field>
        <div v-if="item && item.base">
          <span class="subtitle-1 pb-3 font-weight-bold">
            {{ $t("recommender.segmentSettings") }}:
          </span>
          {{ $t("tag.segmentNotSet") }}
        </div>
        <div v-else>
          <div class="subtitle-1 pb-3 font-weight-bold">
            {{ $t("recommender.segmentSettings") }}
          </div>
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                :clearable="true"
                :items="availableAccountTypeLabels"
                item-text="title"
                item-value="id"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="type"
                :label="attributeLabels.type"
                v-model="tag.segment.type"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :clearable="true"
                :items="availableStages"
                :label="attributeLabels.stage"
                v-model="tag.segment.stage"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="language"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :clearable="true"
                item-text="key"
                item-value="gender"
                :items="availableGenders"
                :label="this.attributeLabels.gender"
                v-model="tag.segment.gender"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="gender"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :clearable="true"
                :items="availableAges"
                :label="this.attributeLabels.ageGroup"
                v-model="tag.segment.ageGroup"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="ageGroup"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :clearable="true"
                item-text="label"
                item-value="key"
                :items="availableLanguageLabels"
                :label="this.attributeLabels.language"
                v-model="tag.segment.language"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="language"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <div class="mt-5">
          <div class="subtitle-1 mb-3 font-weight-bold">
            {{ $t("tag.settings") }}
          </div>
          <div>
            <v-color-picker
              hide-inputs
              hide-mode-switch
              hide-canvas
              class="ma-2"
              :swatches="swatches"
              show-swatches
              v-model="tag.settings.color"
              :label="attributeLabels.color"
            ></v-color-picker>
          </div>
        </div>
        <v-alert v-if="tagError" dense outlined type="error">
          {{ $t("tag.notUniq") }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">
          {{ $t("close") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="updateItem">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

import validator from "@/mixin/validator";
import { required } from "vuelidate/lib/validators";

import { PATCH_TAG } from "@/store/actions/tag";
import { FETCH_ACCOUNT_TYPES } from "@/store/actions/donor";

export default {
  name: "TagForm",
  mixins: [validator],
  props: {
    item: Object,
  },
  data() {
    return {
      modal: 0,
      dataLoading: false,
      formErrors: null,
      tag: this.item
        ? {
            parentId: this.item.parentId,
            title: this.item.title,
            segment: this.item.segment
              ? this.item.segment
              : {
                  type: null,
                  gender: null,
                  ageGroup: null,
                  language: null,
                  stage: null,
                },
            settings: this.item.settings
              ? this.item.settings
              : {
                  color: {
                    alpha: 1,
                    hex: "#90CAF9",
                    hexa: "#90CAF9FF",
                    hsla: {
                      h: 206.85714285714286,
                      s: 0.8974358974358974,
                      l: 0.7705882352941176,
                      a: 1,
                    },
                    hsva: {
                      h: 206.85714285714286,
                      s: 0.4216867469879518,
                      v: 0.9764705882352941,
                      a: 1,
                    },
                    hue: 206.85714285714286,
                    rgba: { r: 144, g: 202, b: 249, a: 1 },
                  },
                },
          }
        : {
            title: "",
            parentId: null,
            segment: {
              type: null,
              gender: null,
              ageGroup: null,
              language: null,
              stage: null,
            },
            settings: {
              color: null,
            },
          },
      attributeLabels: {
        gender: this.$t("account.attribute.gender"),
        type: this.$t("account.attribute.type"),
        ageGroup: this.$t("account.attribute.age"),
        language: this.$t("account.attribute.language"),
        stage: this.$t("account.attribute.stage"),
        title: this.$t("tag.attributes.title"),
        color: this.$t("tag.attributes.color"),
      },
      swatches: [
        ["#90CAF9", "#BBDEFB", "#B3E5FC", "#B2EBF2"],
        ["#B2DFDB", "#A5D6A7", "#C8E6C9", "#DCEDC8"],
        ["#F0F4C3", "#FFF9C4", "#FFECB3", "#FFE0B2"],
        ["#FFCCBC", "#FFCDD2", "#F8BBD0", "#E1BEE7"],
        ["#D1C4E9", "#C5CAE9", "#B0BEC5", "#D7CCC8"],
      ],
      availableAccountTypeLabels: [],
    };
  },
  computed: {
    ...mapGetters("account/donor", [
      "availableGenders",
      "availableLanguages",
      "availableStages",
      "availableAges",
      "availableLanguageLabels",
    ]),
    tagErrors: function () {
      return _.merge(
        {
          title: null,
          parentId: null,
          segment: {
            type: null,
            gender: null,
            ageGroup: null,
            language: null,
            stage: null,
          },
          settings: {
            color: null,
          },
        },
        this.validator.errors.tag
      );
    },
    tagError: function () {
      return (
        this.formErrors &&
        this.formErrors.name &&
        this.formErrors.name.indexOf("not_unique") > -1
      );
    },
  },
  methods: {
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        let { tag } = this;
        this.$store
          .dispatch("tag/" + PATCH_TAG, {
            tag,
            id: this.item ? this.item.id : null,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              } else {
                this.formErrors = payload.errors;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    fetchAccountTypes: function () {
      this.$store
        .dispatch("account/donor/" + FETCH_ACCOUNT_TYPES, {
          limit: 50,
          offset: 0,
        })
        .then(
          (payload) => {
            this.availableAccountTypeLabels = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  validations: function () {
    return {
      tag: {
        title: { required },
        parentId: { required },
      },
    };
  },
  mounted: function () {
    this.fetchAccountTypes();
  },
};
</script>

<style scoped>
.row + .row,
.col,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 0;
  padding-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>
