<template>
  <v-card>
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      <span v-if="item && item.id">
        {{
          $t("tag.assignTag", {
            title: item.title,
          })
        }}
      </span>
      <span v-else>
        {{ $t("tag.assignTags") }}
      </span>
      <v-btn icon color="white" :title="$t('close')" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="px-3" style="position: relative">
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <v-checkbox
          name="enabled"
          :label="attributeLabels.tagLinkedAccounts"
          v-model="tagLinkedAccounts"
          :true-value="true"
          :false-value="false"
        ></v-checkbox>
        <div v-if="item && item.id">
          <v-textarea
            autocomplete="no-autocomplete"
            persistent-hint
            :hint="$t('tag.enterAccountItems')"
            v-model="accounts"
          ></v-textarea>
        </div>
        <div v-else>
          <v-radio-group row v-model="importData">
            <v-radio :label="$t('tag.import')" :value="1"></v-radio>
            <v-radio :label="$t('tag.enter')" :value="0"></v-radio>
          </v-radio-group>
          <div v-if="importData === 1">
            <v-file-input
              persistent-hint
              :hint="$t('tag.importHint')"
              accept=".csv"
              small-chips
              truncate-length="16"
              v-model="importFile"
              :error-messages="fileError"
            ></v-file-input>
            <div class="text-end">
              <v-btn
                x-small
                color="blue darken-1"
                text
                @click="downloadExample"
              >
                <v-icon x-small>mdi-file-delimited-outline</v-icon>
                {{ $t("downloadExample") }}
              </v-btn>
            </div>
          </div>
          <div v-else>
            <v-textarea
              autocomplete="no-autocomplete"
              persistent-hint
              :hint="$t('tag.enterItems')"
              v-model="enteredData"
            ></v-textarea>
          </div>
        </div>
        <v-alert
          v-if="parsedData.length"
          text
          type="info"
          class="pa-3 mt-3 mb-0 text-caption"
        >
          {{
            $t("tag.importPreview", {
              count: parsedData.length,
            })
          }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">
          {{ $t("close") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="addItems">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { MARK_ACCOUNTS } from "@/store/actions/tag";

export default {
  name: "TagAssignForm",
  props: {
    item: Object,
  },
  data() {
    return {
      modal: 0,
      dataLoading: false,
      formErrors: null,
      importData: 1,
      parsedData: [],
      importFile: null,
      enteredData: "",
      accounts: "",
      exampleData: [
        ["accountId", "slug"],
        ["100016857047374", "it-sfera"],
        ["100035313931147", "it-sfera"],
        ["100016572341088", "kosmos"],
        ["100016590949340", "crime"],
      ],
      tagLinkedAccounts: false,
      attributeLabels: {
        tagLinkedAccounts: this.$t("tag.attributes.tagLinkedAccounts"),
      },
    };
  },
  computed: {
    isCsv: function () {
      return this.importFile
        ? this.importFile.name.split(".").pop() === "csv"
        : "";
    },
    fileError: function () {
      return !this.importFile || this.isCsv ? "" : this.$t("defaultErrors.csv");
    },
  },
  watch: {
    importFile: function () {
      let vm = this;
      if (this.importFile && this.isCsv) {
        this.$papa.parse(this.importFile, {
          delimiter: "", // auto-detect
          newline: "", // auto-detect
          quoteChar: '"',
          escapeChar: '"',
          header: true,
          dynamicTyping: true,
          preview: 0,
          encoding: "",
          delimitersToGuess: [",", "\t", "|", ";"],
          // ?? callback function ??header: true,
          complete: function (results) {
            vm.parsedData = results.data;
          },
        });
      } else {
        vm.parsedData = [];
      }
    },
    importData: function () {
      this.parsedData = [];
      this.importFile = null;
      this.enteredData = null;
    },
    enteredData: function () {
      this.parsedData = this.enteredData
        ? this.enteredData
            .split("\n")
            .filter(function (item) {
              return item && item.includes("=");
            })
            .map(function (item) {
              const parsedData = item.split("=");
              return {
                accountId: parsedData[0],
                slug: parsedData[1],
              };
            })
        : [];
    },
    accounts: function () {
      const vm = this;
      this.parsedData =
        vm.item && this.accounts
          ? this.accounts
              .split("\n")
              .filter(function (item) {
                return item;
              })
              .map(function (item) {
                return {
                  accountId: item,
                  slug: vm.item.slug,
                };
              })
          : [];
    },
  },
  methods: {
    downloadExample: function () {
      this.$papa.download(
        this.$papa.unparse(this.exampleData, {
          quotes: false, //or array of booleans
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          header: true,
          newline: "\r\n",
          skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
          columns: null, //or array of strings
        }),
        "AssignTagExample"
      );
    },
    addItems: function () {
      this.dataLoading = true;
      const { parsedData, tagLinkedAccounts } = this;
      this.$store
        .dispatch("tag/" + MARK_ACCOUNTS, {
          data: {
            items: parsedData,
            tagLinkedAccounts,
          },
        })
        .then(
          (payload) => {
            this.dataLoading = false;
            if (payload.status) {
              this.$emit("submitted");
              if (payload.data.taskHash) {
                this.$notify({
                  group: "messages",
                  title: this.$t("tag.importMessage"),
                  text: this.$t("tag.importStarted"),
                });
                this.$socket.emit("subscribe", {
                  channel: "markAccounts",
                  subChannel: payload.data.taskHash,
                });
              }
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.row + .row,
.col,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 0;
  padding-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>
