<template>
  <div>
    <v-row class="d-flex justify-end">
      <v-col>
        <h1>{{ $t("tag.listTitle") }}</h1>
      </v-col>
      <v-col cols="4" class="text-end">
        <v-btn
          small
          color="primary"
          :title="$t('tag.assignTags')"
          @click="assignItem(null)"
          class="mr-2"
        >
          {{ $t("tag.assignTags") }}
        </v-btn>
        <v-btn small color="primary" @click="editCategoryItem(null)">
          {{ $t("tag.addTagCategory") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="search"
          :label="$t('tag.searchText')"
          flat
          filled
          rounded
          dense
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-overlay
      :absolute="true"
      :value="dataLoading"
      justify-center
      align-center
      opacity="0.2"
    >
      <v-progress-circular size="64" indeterminate></v-progress-circular>
    </v-overlay>
    <!--    Data table     -->
    <v-treeview
      :load-children="loadChildren"
      :items="preparedItems"
      return-object
      expand-icon="mdi-chevron-down"
      item-text="title"
      :open.sync="open"
      transition
      :filter="filter"
      :search="search"
      ref="tree"
    >
      <template v-slot:label="{ item }">
        <tag-category
          v-if="item.level === 0"
          :item="item"
          @addItem="editItem({ parentId: item.id })"
          @editItem="editCategoryItem(item)"
          @deleteItem="deleteConfirmation(item)"
        ></tag-category>
        <tag-item
          v-else
          :item="item"
          @editItem="editItem(item)"
          @deleteItem="deleteConfirmation(item)"
          @assignItem="assignItem(item)"
        ></tag-item>
      </template>
    </v-treeview>
    <!--  Setting form dialog    -->
    <v-dialog v-model="editDialog" max-width="700px">
      <tag-form
        v-if="editDialog"
        :item="selectedItem"
        @close="closeEditDialog"
        @submitted="
          loadItems();
          loadChildren({ id: selectedItem.parentId });
          closeEditDialog();
        "
      ></tag-form>
    </v-dialog>
    <v-dialog v-model="categoryDialog" max-width="700px">
      <tag-category-form
        v-if="categoryDialog"
        :item="selectedCategory"
        @close="closeCategoryDialog"
        @submitted="
          loadItems();
          closeCategoryDialog();
        "
      ></tag-category-form>
    </v-dialog>
    <v-dialog v-model="assignDialog" max-width="700px">
      <tag-assign-form
        v-if="assignDialog"
        :item="selectedItem"
        @close="closeAssignDialog"
        @submitted="closeAssignDialog"
      ></tag-assign-form>
    </v-dialog>
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          {{ $t("deleteConfirmation") }}
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          v-if="
            selectedItem.level > 0 ||
            (selectedItem.childrenStatistics &&
              selectedItem.childrenStatistics.length === 0)
          "
          class="mt-2"
        >
          {{ $t("deleteConfirmationText", selectedItem) }}
        </v-card-text>
        <v-card-text v-else class="mt-2">
          {{ deleteCategoryWarning }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteItem(selectedItem)">
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import BaseListPage from "@/views/base/BaseListPage";

import TagItem from "@/views/tag/list/TagItem";
import TagCategory from "@/views/tag/list/TagCategory";
import TagForm from "@/views/tag/TagForm";
import TagCategoryForm from "@/views/tag/TagCategoryForm";
import TagAssignForm from "@/views/tag/TagAssignForm";

import { mapGetters, mapState } from "vuex";

import { defaultFilters, multipleFilters } from "@/store/modules/tag/state";

import {
  SET_FILTER,
  FETCH_TAG_CATEGORIES,
  FETCH_TAGS,
  DELETE_TAG,
} from "@/store/actions/tag";
import { FETCH_SERVICES } from "@/store/actions/service";

export default {
  name: "TagList",
  extends: BaseListPage,
  components: { TagCategory, TagItem, TagForm, TagCategoryForm, TagAssignForm },
  data: function () {
    return {
      viewDialog: false,
      categoryDialog: false,
      assignDialog: false,
      selectedCategory: null,
      viewedItem: null,
      selectedTarget: 0,
      deleteDialog: false,
      defaultFilters: _.merge({}, defaultFilters),
      multipleFilters,
      open: [],
      search: null,
      caseSensitive: false,
    };
  },
  computed: {
    ...mapState("tag", ["pagination", "filters", "sort", "emptyFilters"]),
    ...mapGetters("service", ["scopes", "getServiceName"]),
    ...mapGetters("tag", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "children",
      "pageCount",
      "formattedFilters",
    ]),
    preparedItems: function () {
      let items = [];
      const vm = this;
      vm.items.forEach(function (item) {
        items.push(
          item.childrenQuantity > 0
            ? _.merge({}, item, {
                children: _.get(vm.children, item.id, []),
              })
            : _.merge({}, item)
        );
      });
      return items;
    },
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    deleteCategoryWarning: function () {
      const vm = this;
      let accountQuantity = 0;
      let accountTargets = [];

      if (this.selectedItem && this.selectedItem.childrenStatistics) {
        this.selectedItem.childrenStatistics.forEach(function (item) {
          accountQuantity += item.value;
          accountTargets.push(vm.getServiceName({ scope: item.name }));
        });
      }

      return this.$t("tag.deleteConfirmationCategory", {
        title: this.selectedItem ? this.selectedItem.title : "",
        totalAccounts: accountQuantity,
        targets: accountTargets.join(", "),
      });
    },
  },
  watch: {
    search: function () {
      const vm = this;
      vm.preparedItems.forEach(function (item) {
        if (
          item.childrenQuantity > 0 &&
          item.childrenQuantity !== item.children.length
        ) {
          vm.fetchChildren(item);
        }
      });
    },
  },
  methods: {
    viewItem: function (item) {
      this.viewedItem = item;
      this.viewDialog = true;
    },
    applyFilters: function (data) {
      this.$store.commit("tag/" + SET_FILTER, data.data);
    },
    loadItems: function () {
      return new Promise((resolve, reject) => {
        this.dataLoading = true;
        this.$store
          .dispatch("tag/" + FETCH_TAG_CATEGORIES)
          .then(
            () => {
              this.dataLoading = false;
              if (
                this.viewedItem &&
                _.map(this.items, "id").indexOf(this.viewedItem.id) === -1
              ) {
                this.viewedItem = null;
                this.viewDialog = null;
              }
              resolve();
            },
            (err) => {
              console.log("Err", err);
              reject();
            }
          )
          .catch(() => {});
      });
    },
    loadChildren: function (item) {
      return new Promise(() => {
        this.fetchChildren(item)
          .then(() => {
            let nodes = this.$refs.tree.nodes;
            for (const nodeKey in nodes) {
              const node = nodes[nodeKey];
              if (node.vnode.key === item.id) {
                node.vnode.hasLoaded = true;
                node.vnode.isLoading = false;
                node.vnode.isOpen = true;
                this.$refs.tree.updateOpen(item.id, true);
                this.$refs.tree.emitOpen();
              }
            }
            this.$refs.tree.updateVnodeState(item.id);
          })
          .catch(() => {});
      });
    },
    fetchChildren: function (item) {
      return new Promise((resolve) => {
        this.$store
          .dispatch("tag/" + FETCH_TAGS, { parentId: item.id })
          .then(() => {
            resolve();
          })
          .catch(() => {});
      });
    },
    loadServices: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("service/" + FETCH_SERVICES, null, { root: true })
        .catch(() => {});
    },
    editCategoryItem: function (item) {
      this.selectedCategory = item;
      this.categoryDialog = true;
    },
    closeCategoryDialog: function () {
      this.selectedCategory = null;
      this.categoryDialog = false;
    },
    assignItem: function (item) {
      this.selectedItem = item;
      this.assignDialog = true;
    },
    closeAssignDialog: function () {
      this.selectedItem = null;
      this.assignDialog = false;
    },
    deleteConfirmation: function (item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    deleteItem: function (item) {
      this.dataLoading = true;
      if (this.viewedItem && this.viewedItem.id === item.id) {
        this.selectedItem = null;
        this.viewDialog = false;
      }
      this.$store
        .dispatch("tag/" + DELETE_TAG, item)
        .then(
          (response) => {
            if (response.status === true) {
              this.$notify({
                group: "messages",
                title: this.$t("recommender.deleteSettings"),
                text: this.$t("recommender.deleteSettingsSuccess"),
              });
              this.dataLoading = false;
              this.selectedItem = null;
              this.deleteDialog = false;
              this.loadItems();
              if (item.level > 0) {
                this.fetchChildren({ id: item.parentId });
              }
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  mounted() {
    if (!this.applyRouteParams()) {
      this.loadItems();
    }
    this.loadServices();
  },
  sockets: {
    markAccounts: function (msg) {
      const vm = this;
      const { payload } = msg;
      if (payload && payload.status === "completed") {
        vm.$notify({
          group: "messages",
          closeOnClick: true,
          duration: 50000,
          title: this.$t("tag.listTitle"),
          text: this.$t("tag.importFinished", payload),
        });
      }
      const { taggedCategories } = payload;
      if (taggedCategories) {
        vm.loadItems();
        taggedCategories.forEach(function (categoryId) {
          vm.fetchChildren({ id: categoryId });
        });
      }
    },
  },
};
</script>

<style>
.v-treeview-node__level {
  width: 0 !important;
}
</style>
