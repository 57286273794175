<template>
  <v-sheet outlined class="mt-1">
    <v-card-text class="pr-2 pl-4">
      <v-row>
        <v-col sm="4" md="4" lg="2">
          {{ item.title }}
        </v-col>
        <v-col sm="4" md="2" lg="1">
          <v-chip small outlined label>{{ item.slug }}</v-chip>
        </v-col>
        <v-col sm="4" md="6" lg="2">
          <div v-for="(statisticItem, i) in item.statistics" :key="i">
            <router-link
              :to="{
                name: 'AccountList',
                query: {
                  tags: [item.id],
                  target: [statisticItem.name],
                  selectedFilters: ['tags', 'target'],
                },
              }"
              class="text-decoration-none grey--text text--darken-4"
            >
              <v-icon
                v-text="
                  'mdi-' +
                  (statisticItem.name === 'vkontakte'
                    ? 'alpha-b-box'
                    : statisticItem.name)
                "
              ></v-icon>
              <span v-text="statisticItem.value" class="pl-1"></span>
            </router-link>
          </div>
        </v-col>
        <v-col sm="6" md="8" lg="5">
          <div v-if="hasFormattedSegment">
            <div v-for="(segment, label) in formattedSegment" :key="label">
              <span class="grey--text body-2 mb-0">
                {{ attributeLabels[label] }}:
              </span>
              {{ prepareAttributeValue(label, segment) }}
            </div>
          </div>
          <div v-else class="text-caption font-weight-light">
            {{ $t("tag.segmentNotSet") }}
          </div>
        </v-col>
        <v-col sm="3" md="2" lg="1">
          <v-chip
            small
            v-if="item.settings.color.hex"
            :color="item.settings.color.hex"
            >{{ item.settings.color.hex }}</v-chip
          >
        </v-col>
        <v-col sm="3" md="2" lg="1" class="d-flex justify-space-between pr-5">
          <v-btn
            v-if="!item.settings.disabledMarkAccount"
            x-small
            icon
            color="primary"
            @click="$emit('assignItem')"
          >
            <v-icon>mdi-link-plus</v-icon>
          </v-btn>
          <v-btn x-small icon color="primary" @click="$emit('editItem')">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="!item.base"
            x-small
            icon
            color="primary"
            @click="$emit('deleteItem')"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-sheet>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  name: "TagItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      attributeLabels: {
        gender: this.$t("account.attribute.gender"),
        type: this.$t("account.attribute.type"),
        ageGroup: this.$t("account.attribute.age"),
        language: this.$t("account.attribute.language"),
        stage: this.$t("account.attribute.stage"),
        title: this.$t("tag.attributes.title"),
        color: this.$t("tag.attributes.color"),
      },
    };
  },
  computed: {
    ...mapGetters("account/donor", [
      "availableGenders",
      "availableLanguages",
      "availableStages",
      "availableAges",
      "availableLanguageLabels",
    ]),
    formattedSegment: function () {
      let segment = {};
      _.forIn(this.item.segment, function (value, key) {
        if (value && value.length) {
          segment[key] = value;
        }
      });
      return segment;
    },
    hasFormattedSegment: function () {
      return !this.isEmpty(this.formattedSegment);
    },
  },
  methods: {
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    prepareAttributeValue: function (attribute, value) {
      let result = value;
      const component = this;
      if (_.isArray(value)) {
        return value
          .map(function (itemValue) {
            return component.prepareAttributeValue(attribute, itemValue);
          })
          .join(", ");
      }
      switch (attribute) {
        case "language": {
          let language = _.find(this.availableLanguageLabels, {
            key: value,
          });
          if (language) {
            result = language.label;
          }
          break;
        }
        case "gender": {
          let gender = _.find(this.availableGenders, {
            gender: value,
          });
          if (gender) {
            result = gender.key;
          }
          break;
        }
        case "tagId": {
          let tag = _.find(this.item.tags, { id: value });
          result = tag ? tag.title : "";
          break;
        }
      }
      return result;
    },
  },
};
</script>
