<template>
  <v-card>
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      <span v-if="item">
        {{
          $t("tag.editTagCategory", {
            tag: item.title,
          })
        }}
      </span>
      <span v-else>
        {{ $t("tag.addTagCategory") }}
      </span>
      <v-btn icon color="white" :title="$t('close')" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="px-3" style="position: relative">
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <v-text-field
          name="name"
          :label="attributeLabels.title"
          :error-messages="tagErrors.title"
          v-model="tag.title"
          type="text"
        ></v-text-field>
        <div class="mt-5">
          <div class="subtitle-1 mb-3 font-weight-bold">
            {{ $t("tag.settings") }}
          </div>
          <div>
            <v-checkbox
              name="enabled"
              :label="attributeLabels.multiple"
              v-model="tag.settings.multiple"
              :true-value="true"
              :false-value="false"
            ></v-checkbox>
          </div>
        </div>
        <v-alert v-if="tagError" dense outlined type="error">
          {{ $t("tag.notUniq") }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">
          {{ $t("close") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="updateItem">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

import validator from "@/mixin/validator";
import { required } from "vuelidate/lib/validators";

import { PATCH_TAG } from "@/store/actions/tag";

export default {
  name: "TagCategoryForm",
  mixins: [validator],
  props: {
    item: Object,
  },
  data() {
    return {
      modal: 0,
      dataLoading: false,
      formErrors: null,
      tag: this.item
        ? {
            title: this.item.title,
            settings: this.item.settings
              ? this.item.settings
              : {
                  multiple: false,
                },
          }
        : {
            title: "",
            settings: {
              multiple: false,
            },
          },
      attributeLabels: {
        gender: this.$t("account.attribute.gender"),
        type: this.$t("account.attribute.type"),
        ageGroup: this.$t("account.attribute.age"),
        language: this.$t("account.attribute.language"),
        stage: this.$t("account.attribute.stage"),
        title: this.$t("tag.attributes.title"),
        multiple: this.$t("tag.attributes.multiple"),
      },
    };
  },
  computed: {
    ...mapGetters("account/donor", [
      "availableGenders",
      "availableLanguages",
      "availableStages",
      "availableAges",
      "availableLanguageLabels",
    ]),
    tagErrors: function () {
      return _.merge(
        {
          title: null,
          settings: {
            multiple: null,
          },
        },
        this.validator.errors.tag
      );
    },
    tagError: function () {
      return (
        this.formErrors &&
        this.formErrors.name &&
        this.formErrors.name.indexOf("not_unique") > -1
      );
    },
  },
  methods: {
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        let { tag } = this;
        this.$store
          .dispatch("tag/" + PATCH_TAG, {
            tag,
            id: this.item ? this.item.id : null,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              } else {
                this.formErrors = payload.errors;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
  },
  validations: function () {
    return {
      tag: {
        title: { required },
      },
    };
  },
};
</script>

<style scoped>
.row + .row,
.col,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 0;
  padding-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>
