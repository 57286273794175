var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_vm._v(" "+_vm._s(_vm.item.title)+" "),_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","label":"","outlined":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.item.childrenQuantity ? _vm.item.childrenQuantity : 0)+" "+_vm._s(_vm.$t("tag.children")))]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","icon":"","color":"primary","title":_vm.$t('tag.addTag')},on:{"click":function($event){return _vm.$emit('addItem')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle-outline ")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('editItem')}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),(!_vm.item.base)?_c('v-btn',{staticClass:"ml-1",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('deleteItem')}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()],1),_c('v-col',{attrs:{"lg":"3"}},_vm._l((_vm.item.statistics),function(statisticItem,i){return _c('div',{key:i},[_c('router-link',{staticClass:"text-decoration-none grey--text text--darken-4",attrs:{"to":{
          name: 'AccountList',
          query: {
            tags: [_vm.item.id],
            selectedFilters: ['tags'],
          },
        }}},[_c('v-icon',{domProps:{"textContent":_vm._s(
            'mdi-' +
            (statisticItem.name === 'vkontakte'
              ? 'alpha-b-box'
              : statisticItem.name)
          )}}),_c('span',{staticClass:"pl-1",domProps:{"textContent":_vm._s(statisticItem.value)}})],1)],1)}),0),_c('v-col',{attrs:{"lg":"3"}},[(_vm.item.settings && _vm.item.settings.multiple)?_c('div'):_c('div')])],1)}
var staticRenderFns = []

export { render, staticRenderFns }