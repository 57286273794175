<template>
  <v-row>
    <v-col lg="6">
      {{ item.title }}
      <v-chip x-small class="ml-2" label outlined color="blue">
        {{ item.childrenQuantity ? item.childrenQuantity : 0 }}
        {{ $t("tag.children") }}</v-chip
      >
      <v-btn
        small
        icon
        color="primary"
        :title="$t('tag.addTag')"
        @click="$emit('addItem')"
        class="ml-3"
      >
        <v-icon small>mdi-plus-circle-outline </v-icon>
      </v-btn>
      <v-btn
        x-small
        icon
        color="primary"
        @click="$emit('editItem')"
        class="ml-1"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="!item.base"
        x-small
        icon
        color="primary"
        @click="$emit('deleteItem')"
        class="ml-1"
      >
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </v-col>
    <v-col lg="3">
      <div v-for="(statisticItem, i) in item.statistics" :key="i">
        <router-link
          :to="{
            name: 'AccountList',
            query: {
              tags: [item.id],
              selectedFilters: ['tags'],
            },
          }"
          class="text-decoration-none grey--text text--darken-4"
        >
          <v-icon
            v-text="
              'mdi-' +
              (statisticItem.name === 'vkontakte'
                ? 'alpha-b-box'
                : statisticItem.name)
            "
          ></v-icon>
          <span v-text="statisticItem.value" class="pl-1"></span>
        </router-link>
      </div>
    </v-col>
    <v-col lg="3">
      <div v-if="item.settings && item.settings.multiple"></div>
      <div v-else></div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TagCategory",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
